import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Util from '@/support/utility';

@Component
export default class PromptComponent extends Vue {
    options = {
    };

    key: string = 'prompt';

    @Prop({ default: <any>'Title goes here' })
    title: string;

    @Prop({ default: <any>'Message goes here' })
    message: string;

    @Prop({ default: <any>'OK' })
    submitText: string;

    @Prop({ default: <any>'Cancel' })
    cancelText: string;

    @Prop({ default: <any>300 })
    height: string;

    hideSubmit = false;
    hideCancel = false;

    created() {
        this.key = 'prompt-' + Util.randomString();
    }

    mounted() {
    }

    show() {
        (<any>this.$modal).show(this.key);
    }

    hide() {
        (<any>this.$modal).hide(this.key);
    }

    submit(event) {
        this.hide();

        this.$emit("onClosed", true);
    }

    cancel(event) {
        this.hide();

        this.$emit("onClosed", false);
    }

}
